import React from 'react'
import { pure } from 'recompose'
import { withRouter, useLocation} from 'react-router-dom'
import { Button, Link } from '../../Atoms'
import { SocialIcons } from '../../Molecules'
import { logo } from '../../Assets'
import BBB from './BBB.png'
import './Footer.css'

const Footer = ({ location }) => {
    const isMobile = window.innerWidth < 800

    const subscribeBox = (
        <div className='row subscribeWrap'>
            <input className='roundedSmall' placeholder={window.location.pathname.includes('/investor-ch') ? '您的电子邮件' : 'Your Email'} />
            <Button >{window.location.pathname.includes('/investor-ch') ? '订阅' : 'Subscribe'}</Button>
        </div>
    )

    let year = new Date().getFullYear();
    //const queryString = useLocation().search;

    return (
        <div className={`Footer ${isMobile ? 'fontSizeM' : 'fontSizeXXS'}`}>
            <div className='darkerBackground'></div>
            <div className='lightishBackground withBackground pagePadding paddingTopL paddingBottomL'>
                {
                    isMobile ? subscribeBox : <span></span>
                }
                <div className='row items'>
                    <Link to="/" className={`menuItem ${location.pathname === '/' ? 'active' : ''}`} >
                        {window.location.pathname.includes('/investor-ch') ? '首页' : 'HOME'}
                </Link>
                    <Link to="/aboutus" className={`menuItem ${location.pathname === '/aboutus' ? 'active' : ''}`}>
                        {window.location.pathname.includes('/investor-ch') ? '关于我们' : 'ABOUT US'}
                </Link>
                    <Link to="/newsandevents" className={`menuItem ${location.pathname === '/newsandevents' ? 'active' : ''}`}>
                        {window.location.pathname.includes('/investor-ch') ? '新闻与活动' : 'NEWS AND EVENTS'}
                </Link>
                    <Link to="/products" className={`menuItem ${location.pathname === '/products' ? 'active' : ''}`}>
                        {window.location.pathname.includes('/investor-ch') ? '产品' : 'PRODUCTS'}
                </Link>
                    <Link to="/submitadeal" className={`menuItem ${location.pathname === '/submitadeal' ? 'active' : ''}`}>
                        {window.location.pathname.includes('/investor-ch') ? '提交交易' : 'SUBMIT A DEAL'}
                </Link>
                    <Link to="/loyaltyprograms" className={`menuItem ${location.pathname === '/loyalty' ? 'active' : ''}`}>
                        {window.location.pathname.includes('/investor-ch') ? '忠诚计划' : 'LOYALTY PROGRAM'}
                </Link>
                    <Link to="/contact" className={`menuItem ${location.pathname === '/contact' ? 'active' : ''}`}>
                        {window.location.pathname.includes('/investor-ch') ? '联系方式' : 'CONTACT'}
                </Link>

                     {
                        !isMobile ? subscribeBox : <span></span>
                    }
{/*                    <a href="https://www.bbb.org/ca/on/north-york/profile/mortgage-lenders/ginkgo-mortgage-investment-corporation-0107-1373779" target="_blank">
                        <img className='bbb' src={BBB} alt='' />
                    </a>
 */}

                </div>
                <div className='row items secondRow'>
                    <Link to="/blogfeed" className={`menuItem ${location.pathname === '/blogfeed' ? 'active' : ''}`}>
                        {window.location.pathname.includes('/investor-ch') ? '我们的博客' : 'OUR BLOG'}
                </Link>
                    <Link to="/faq" className={`menuItem ${location.pathname === '/faq' ? 'active' : ''}`}>
                        {window.location.pathname.includes('/investor-ch') ? '常见问题解答' : 'FREQUENTLY ASKED QUESTIONS'}
                </Link>
                     <a href="terms-and-conditions.htm" target="_blank">
                        {window.location.pathname.includes('/investor-ch') ? '条款和条件' : 'TERMS AND CONDITIONS'}
                    </a>
                 </div>
                <div className='centerText paddingTopM paddingBottomM'>
                    <img className='logo' src={logo} alt='' />

                    <div className='paddingTopM'>
                        <SocialIcons />
                    </div>
                    <div className='darkText paddingTopM fontSizeXXS'>
                        (c){year} {window.location.pathname.includes('/investor-ch') ? 'Ginkgo | 版权所有' : 'Ginkgo | All rights reserved'}
                    </div>
                    <p className='privacy paddingTopM fontSizeXXS'>
                        {window.location.pathname.includes('/investor-ch') ? 'Ginkgo MIC 致力于尊重个人隐私，并认识到需要适当管理和保护您同意提供给我们的个人信息。除非为了完成您的请求，否则我们不会将您的信息与我们组织之外的任何第三方共享。' : 'Ginkgo MIC is committed to respecting the privacy of individuals and recognizes a need for the appropriate management and protection of any personal information that you agree to provide to us. We will not share your information with any third party outside of our organization, other than as necessary to fulfill your request.'}                   
                    </p>
                </div>

            </div>
            <div className='darkBackground'></div>
        </div>
    )
}

export default pure(withRouter(Footer))