import React from "react";
import { pure } from "recompose";
import { H2, H1, Button } from "../../../../../../Atoms";
import { OnScrollAnimatior } from "../../../../../../Molecules";
import "./BodySection.css";

import Grid from "@material-ui/core/Grid";

import image2 from "./ginkgo-image.png";

import TimelineCard from "./TimelineCard/TimelineCard";
const timelineCards = [
    {
        number: 1,
        title: <span>跟我们联系</span>,
        text: (
            <span>
                房贷投资是参与穩健的资产投资之一种更简单、更实惠的方式！
            </span>
        )
    },
    {
        number: 2,
        title: <span>与EMD会面</span>,
        text: (
            <span>
                EMD会回答您所有疑问，并确保您在签署文件前充分了解相关信息。您将完成“客户了解表”（Know Your Client）和认购协议。
            </span>
        )
    },
    {
        number: 3,
        title: <span>开始赚取股息！</span>,
        text: (
            <span>
                在您的表格被处理后，您将收到一份欢迎投资的资料包。每次股息到账时，您都会收到短信通知，并通过电子邮件收到每月投资组合报告。
            </span>
        )
    }
];

const BodySection = props => {
    const isMobile = window.innerWidth < 800;
    const TimelineCards = () =>
        timelineCards.map(({ number, title, text }, i) => (
            <Grid item className="paddingRightL zeroRightPaddingMobile">
                <OnScrollAnimatior
                    key={i}
                    animation="fadeInUp"
                    delay={i * 100}
                    preAnimationStyles={{ opacity: 0 }}
                    isMobile={isMobile}
                    onSuccess={scrollAnimatior => console.log("Card Animating", scrollAnimatior)}
                >
                    <TimelineCard number={number} title={title} text={text} />
                </OnScrollAnimatior>
            </Grid>
        ));
    return (
        <div className="BodySection">
            <div className="paddingTopXXL paddingBottomXL">
                <div className="paddingBottomXL">
                    <H1 className="fontSizeLL centerText">运作方式</H1>
                    <div className="fontSizeS lineHeightM fontWeightNormal InnerContent paddingTopM paddingBottomM">
                    根据FSCO的要求，MIC的证券需通过豁免市场经销商（EMD）购买。以下是您开始财富增长的方法：
                    </div>
                    <Grid container direction="row" justify="center" className="marginOnMobile">
                        {TimelineCards()}
                    </Grid>
                </div>

                <Grid container className="fadeInUp" direction="row" justify="center">
                    <Grid item>
                        <div className="timelineLeftSection paddingRightL marginOnMobile">
                            <div className="topSection">
                                <H1 className="fontSizeLL">您的投资旅程从这里开始！</H1>
                                <div className="fontSizeS lineHeightM fontWeightNormal">
                                我们很乐意为您安排与豁免市场交易商的会面，并帮助您通过Ginkgo盈信令您的投资组合收益更高效。请致电：416-901-5133 或发送邮件至：investor@ginkgomic.com
                                </div>
                                <div className="buttonRow">
                                    <Button
                                        onClick={() => {
                                            window.location.assign("mailto:investor@ginkgomic.com");
                                        }}
                                    >
                                        立即开始！
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </Grid>
                    <Grid item justify="center">
                        <div className="paddingLeftL">
              {/*               <img src={image2} className="rightImage" alt="" /> */}
                            <H2 className="lineHeightM fontSizeMM centerText">准备好了吗？我们开始吧！ </H2>
                        </div>
                    </Grid>
                </Grid>
            </div>
        </div>
    );
};
export default pure(BodySection);
