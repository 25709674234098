import React from "react";
import { pure } from "recompose";
import { H2 } from "../../../../../../Atoms";
import "./BodySection.css";
import Grid from "@material-ui/core/Grid";
import image1 from "./image1.png";
import image2 from "./image2.png";
import image3 from "./image3.jpg";
import logo2 from "./logo2.png";
import logo3 from "./logo3.png";
import henry from "./henry.jpg";
import image4 from "./agm21.jpg";
import cruise from "./2021Cruise.png";
import promotion from "./2022Promotion.png";
import AGM from "./2022AGMPressRelease.png"
import RedCross from "./2022RedCross.png"
import special from "./Special.png"
const BodySection = props => {
    const isMobile = window.innerWidth < 800;
    return (
         <div className="pagePadding-Investor paddingTopSL paddingBottomXL BodySection">
{/*               <Grid className="darkerBackground roundedBig" justify="center" container direction="row">
                  <Grid item xs>
                      <img className="mainIamgeSection roundedBig" src={logo2} alt="" />
                  </Grid>
                  <Grid item xs>
                      <div className="paddingLL marginOnMobile">
                          <H2 className="lightText centerText" >2022 Ginkgo MIC Annual General Meeting</H2>
                          <div className="fullWidth fontSizeM fontWeightNormal lightText paddingTopM paddingRightXL">
                              <p>We extend a warm invitation to you to attend our Annual General Meeting. Join our live Zoom session and take a look back at the past year’s fund performance, highlights and achievements, as well to discuss our market forecast and future developments planned for the year ahead as Ginkgo enters into its next decade.
Friends and family are welcome to register and attend.</p>
                          </div>
                          <div className="paddingBottomML paddingRightM paddingTopL fullWidth">
                           <div className="whiteBackground paddingL centerText">                             
                            <div className="fontSizeM fontWeightBold">LIVE ZOOM WEBINAR</div>
                                <div className="fontSizeXS fontWeightBold">Mandarin</div>
                                   <div className="fontSizeXS fontWeightNormal ">
                                        <p>Friday, February 4th 2022 2PM EST / 11AM PT</p>
                                         <p>
                                         <a target="_blank" href="https://us02web.zoom.us/meeting/register/tZYpd--orDMuEtImhn3257cUguZeDKI0sPRX"><button className="Button roundedSmall fontSizeXS bold paddingRightL paddingLeftL ctaBackground">REGISTER NOW</button></a>
                                        </p>
                                   </div>

                                   <div className="fontSizeXS fontWeightBold">English</div>
                                   <div className="fontSizeXS fontWeightNormal ">
                                        <p>Friday, February 4th 2022 5PM EST / 2PM PT</p>
                                         <p>
                                         <a target="_blank" href="https://us02web.zoom.us/meeting/register/tZMsde-prz4qG9eH6UzMuK30m0Z3byGRo58u"><button className="Button roundedSmall fontSizeXS bold paddingRightL paddingLeftL ctaBackground">REGISTER NOW</button></a>
                                        </p>
                                   </div>
                                <div className="fontSizeXS fontWeightBold">Cantonese</div>
                                   <div className="fontSizeXS fontWeightNormal ">
                                        <p>Saturday, February 5th 2021 2PM EST / 11AM PT</p>
                                         <p>
                                         <a target="_blank" href="https://us02web.zoom.us/meeting/register/tZIsdumqpjosH9xQS1cu9VUzm7DFXLBeuBu7"><button className="Button roundedSmall fontSizeXS bold paddingRightL paddingLeftL ctaBackground">REGISTER NOW</button></a>
                                        </p>
                                   </div>
                                   </div>
                          </div>

                        </div>
                  </Grid>
              </Grid>
    */}    

<div className="paddingTopXL">
              <Grid className="darkerBackground roundedBig" justify="center" container direction="row">
                  <Grid item xs>
                      <img className="mainIamgeSection roundedBig" src={logo2} alt="" />
                  </Grid>
                  <Grid item xs>
                      <div className="paddingLL marginOnMobile">
                          <H2 className="lightText centerText">特别股息公告：致力于股东价值和信任</H2>                          
                          <div className="paddingBottomM paddingRightM paddingTopM fullWidth">
                            <div className="whiteBackground paddingM centerText">
                                   <div className="fontSizeXS fontWeightNormal ">
                                        <p>2024年4月17日 - Ginkgo抵押贷款投资公司（“Ginkgo MIC”）欣然宣布，其董事会已批准向截至2023年12月31日登记在册的符合条件的股东发放特别股息，每股2.5美分（0.25%），适用于所有A类优先股。</p>
                                         <p>
                                            <a target="_blank" href="https://finance.yahoo.com/news/special-dividends-announcement-commitment-shareholder-130000418.html"><button className="Button roundedSmall fontSizeXS bold paddingRightL paddingLeftL ctaBackground">更多详情</button></a>
                                        </p>
                                   </div>
                            </div>
                        </div>
                      </div>
                  </Grid>
              </Grid>


<div className="paddingTopXL">
              <Grid className="darkerBackground roundedBig" justify="center" container direction="row">
                  <Grid item xs>
                      <img className="mainIamgeSection roundedBig" src={logo2} alt="" />
                  </Grid>
                  <Grid item xs>
                      <div className="paddingLL marginOnMobile">
                          <H2 className="lightText centerText">年度股东大会新闻稿</H2>
                          <div className="fullWidth fontSizeS fontWeightNormal lightText paddingTopS paddingRightS">
                          </div>
                          
                          <div className="paddingBottomM paddingRightM paddingTopM fullWidth">
                            <div className="whiteBackground paddingM centerText">
                                   <div className="fontSizeXS fontWeightNormal ">
                                   <p>Ginkgo抵押贷款投资公司的年度股东大会亮点：2023年卓越表现与增长计划</p>                                    
                                    <p>
                                            <a target="_blank" href="https://finance.yahoo.com/news/ginkgo-mortgage-investment-corporations-agm-161000857.html"><button className="Button roundedSmall fontSizeXS bold paddingRightL paddingLeftL ctaBackground">阅读更多</button></a>
                                    </p>
                                   </div>
                            </div>
                        </div>
                      </div>
                  </Grid>
              </Grid>


       <div className="paddingTopXL">
              <Grid className="darkerBackground roundedBig" justify="center" container direction="row">
                  <Grid item xs>
                      <img className="mainIamgeSection roundedBig" src={logo2} alt="" />
                  </Grid>
                  <Grid item xs>
                      <div className="paddingLL marginOnMobile">
                          <H2 className="lightText centerText">公告</H2>                          
                          <div className="paddingBottomM paddingRightM paddingTopM fullWidth">
                            <div className="whiteBackground paddingM centerText">
                                   <div className="fontSizeXS fontWeightNormal ">
                                        <p>Ginkgo抵押贷款投资公司（“Ginkgo”）已达成一个非凡的里程碑，投资者总权益突破1亿加元！</p>
                                        <p>这一成就是我们卓越投资者对我们坚定信任和支持的见证。它体现了我们为我们的宝贵股东提供卓越回报的承诺和努力。我们不仅仅是在筹集资本；我们在与投资者的信任共同建设未来。</p>
                                        <p>我们非常感激您对我们目标的信任，迫不及待想要与您一起踏上前方激动人心的旅程。感谢您成为我们成功故事的一部分！</p>
                                   </div>
                            </div>
                        </div>
                      </div>
                  </Grid>
              </Grid>

              <div className="paddingTopXL">
              <Grid className="darkerBackground roundedBig" justify="center" container direction="row">
                  <Grid item xs>
                      <img className="mainIamgeSection roundedBig" src={logo2} alt="" />
                  </Grid>
                  <Grid item xs>
                      <div className="paddingLL marginOnMobile">
                          <H2 className="lightText centerText">特别股息公告</H2>                          
                          <div className="paddingBottomM paddingRightM paddingTopM fullWidth">
                            <div className="whiteBackground paddingM centerText">
                                   <div className="fontSizeXS fontWeightNormal ">
                                        <p>Ginkgo抵押贷款投资公司（“Ginkgo”）欣然宣布，其董事会已宣告再次向截至2023年3月31日登记在册的所有A类参与优先股股东发放特别股息，每股2.5美分（0.25%）。该特别股息将于2023年9月30日支付。</p>
                                         <p>
                                            <a target="_blank" href="https://www.accesswire.com/761614/ginkgo-mic-announces-special-dividend-and-increased-annual-dividend-rate-targets"><button className="Button roundedSmall fontSizeXS bold paddingRightL paddingLeftL ctaBackground">更多详情</button></a>
                                        </p>
                                   </div>
                            </div>
                        </div>
                      </div>
                  </Grid>
              </Grid>

              <div className="paddingTopXL">
              <Grid className="darkerBackground roundedBig" justify="center" container direction="row">
                  <Grid item xs>
                      <img className="mainIamgeSection roundedBig" src={logo2} alt="" />
                  </Grid>
                  <Grid item xs>
                      <div className="paddingLL marginOnMobile">
                          <H2 className="lightText centerText">Ginkgo MIC教育网络研讨会系列 第一集</H2>                          
                          <div className="paddingBottomM paddingRightM paddingTopM fullWidth">
                            <div className="whiteBackground paddingM centerText">
                                   <div className="fontSizeXS fontWeightNormal ">
                                        <p>Ginkgo抵押贷款投资公司近期举办了我们的教育系列首集，题为《抵押贷款投资的风险与回报》。我们讨论了当前的市场状况、投资房地产所涉及的风险，以及如何根据您的风险承受能力正确寻找稳健的投资机会。本集由我们公司首席执行官Henry Tse先生亲自主持！</p>
                                         <p>
                                            <a target="_blank" href="https://bit.ly/489Hk8p"><button className="Button roundedSmall fontSizeXS bold paddingRightL paddingLeftL ctaBackground">视频链接</button></a>
                                        </p>
                                   </div>
                            </div>
                        </div>
                      </div>
                  </Grid>
              </Grid>



{/*         <div className="paddingTopXL">
              <Grid className="darkerBackground roundedBig" justify="center" container direction="row">
                  <Grid item xs>
                      <img className="mainIamgeSection roundedBig" src={logo2} alt="" />
                  </Grid>
                  <Grid item xs>
                      <div className="paddingLL marginOnMobile">
                          <H2 className="lightText centerText">Special Dividend Announcement</H2>
                          <div className="fullWidth fontSizeS fontWeightNormal lightText paddingTopS paddingRightS">
                          </div>
                          
                          <div className="paddingBottomM paddingRightM paddingTopM fullWidth">
                            <div className="whiteBackground paddingM centerText">
                                   <div className="fontSizeXS fontWeightNormal ">
                                   <p>Ginkgo Mortgage Investment Corporation ("Ginkgo") is pleased to announce that its Board of Directors declared a special dividend of 2.5 cents per share (0.25%) payable on June 30, 2023, to all Class "A" participating preference shareholders of record as of December 31, 2022. Additionally, effective May 31, 2023, the annual dividend rate target was increased for Class "A" Participating Preference Shares, Series 1 ("Series 1 Preference Shares") from 9.25% to 9.75% and for Class "A" Participating Preference Shares, Series 2 from 8.50% to 9.00%.</p>
                                    <p>
                                            <a target="_blank" href="https://bit.ly/3p8f1pa"><button className="Button roundedSmall fontSizeXS bold paddingRightL paddingLeftL ctaBackground">更多详情</button></a>
                                    </p>
                                   </div>
                            </div>
                        </div>
                      </div>
                  </Grid>
              </Grid>
 */}
        <div className="paddingTopXL">
              <Grid className="darkerBackground roundedBig" justify="center" container direction="row">
                  <Grid item xs>
                      <img className="mainIamgeSection roundedBig" src={logo2} alt="" />
                  </Grid>
                  <Grid item xs>
                      <div className="paddingLL marginOnMobile">
                          <H2 className="lightText centerText">Ginkgo MIC创纪录的利润导致更高的股息目标</H2>
                          <div className="fullWidth fontSizeS fontWeightNormal lightText paddingTopS paddingRightS">
                          </div>
                          
                          <div className="paddingBottomM paddingRightM paddingTopM fullWidth">
                            <div className="whiteBackground paddingM centerText">
                                    <div className="fontSizeXS fontWeightNormal ">
                                    <p>Ginkgo MIC（“Ginkgo”）欣然宣布，董事会已根据加拿大央行12月7日的加息决定修订年度股息目标。即日起，系列1优先股的年股息率将从8.75%提高至9.25%；系列2优先股的年股息率将从8.25%提高至8.5%。</p>
                                    <p>
                                        <a target="_blank" href="https://yhoo.it/3X1ezo2"><button className="Button roundedSmall fontSizeXS bold paddingRightL paddingLeftL ctaBackground">更多详情</button></a>
                                    </p>
                                   </div>
                            </div>
                        </div>
                      </div>
                  </Grid>
              </Grid>


{/*                <div className="paddingTopXL">
              <Grid className="darkerBackground roundedBig" justify="center" container direction="row">
                  <Grid item xs>
                      <img className="mainIamgeSection roundedBig" src={logo2} alt="" />
                  </Grid>
                  <Grid item xs>
                      <div className="paddingLL marginOnMobile">
                          <H2 className="lightText centerText">Special Dividend Announcement</H2>
                          <div className="fullWidth fontSizeS fontWeightNormal lightText paddingTopS paddingRightS">
                          </div>
                          
                          <div className="paddingBottomM paddingRightM paddingTopM fullWidth">
                            <div className="whiteBackground paddingM centerText">
                                   <div className="fontSizeXS fontWeightNormal ">
                                   <p>Ginkgo MIC is pleased to announce that its Board of Directors has declared a <b>special dividend</b> on all qualified Series 1 and Series 2 Class A Preferred Shares. <b>The $0.05 (0.5%) per share</b> dividend is payable to shareholders who were on record as of December 31, 2021 and remained on record until August 31, 2022. Special dividends are a one-time distribution and will be paid out by October 31, 2022. </p>
                                    <p>This special dividend is made possible as a result of strong management control, excellent agent partnership and support, along with a dynamic Ginkgo team, all integral for a record year of corporate profits amid interest rate hikes and worldwide economic downturn. According to the CEO, Henry Tse, “Our team continues to look for ways to reducing our risk exposure and to improve rate of returns. This record year of profits is a validation that we are heading in the right directions.” “Nowadays, inflation is on everyone’s mind. It is important for Ginkgo to keep up our dividends with the interest rate hikes so that our valued investors will not see diminishing returns to their investments. We will continue to evaluate dividend rates as the Bank of Canada continues to hike interest rates to fight inflation,” said director Peter Wong.
                                    </p>
                                   </div>
                            </div>
                        </div>
                      </div>
                  </Grid>
              </Grid>
 */}
              <div className="paddingTopXL">
              <Grid className="darkerBackground roundedBig" justify="center" container direction="row">
                  <Grid item xs>
                      <img className="mainIamgeSection roundedBig" src={logo2} alt="" />
                  </Grid>
                  <Grid item xs>
                      <div className="paddingLL marginOnMobile">
                          <H2 className="lightText centerText">新闻稿：2022年7月市场更新</H2>
                          <div className="fullWidth fontSizeS fontWeightNormal lightText paddingTopS paddingRightS">
                          </div>
                          
                          <div className="paddingBottomM paddingRightM paddingTopM fullWidth">
                            <div className="whiteBackground paddingM centerText">
                                   <div className="fontSizeXS fontWeightNormal ">
                                   <p>GINKGO MIC 在当前经济环境下依然强劲<br/><br/>2022年7月21日和23日，Ginkgo MIC市场分析师会议由首席执行官Henry Tse主持，分为三场会议——英语、普通话和粤语。会议以虚拟形式举行，以便覆盖全国各地的Ginkgo投资者。在会议期间，Henry向Ginkgo的投资者提供了关于当前市场变化、公司财务表现、预测和战略的深刻见解。</p>
                                    <p>
                                        <a target="_blank" href="https://ginkgomic.sharepoint.com/:b:/g/EVTc_LwyaHBLjfSqIQ6qW7sB9TprWA1vq5IIsB4g3ldUgQ?e=dc1yjr"><button className="Button roundedSmall fontSizeXS bold paddingRightL paddingLeftL ctaBackground">阅读更多</button></a>
                                    </p>
                                   </div>
                            </div>
                        </div>
                      </div>
                  </Grid>
              </Grid>


{/*               <div className="paddingTopXL">
              <Grid className="darkerBackground roundedBig" justify="center" container direction="row">
                  <Grid item xs>
                      <img className="mainIamgeSection roundedBig" src={logo2} alt="" />
                  </Grid>
                  <Grid item xs>
                      <div className="paddingLL marginOnMobile">
                          <H2 className="lightText centerText">Special Dividend Announcement</H2>
                          <div className="fullWidth fontSizeS fontWeightNormal lightText paddingTopS paddingRightS">
                          </div>
                          
                          <div className="paddingBottomM paddingRightM paddingTopM fullWidth">
                            <div className="whiteBackground paddingM centerText">
                                   <div className="fontSizeXS fontWeightNormal ">
                                   <p>Ginkgo MIC is pleased to announce that its Board of Directors has declared a special dividend on all qualified Series 1 and Series 2 Class A Preferred Shares. The $0.50 (0.5%) per share dividend is payable to shareholders who were on record as of December 31, 2021 and remained on record until August 31, 2022. Special dividends are a one-time distribution and will be paid out by October 31, 2022. </p>
                                    <p>
                                        <a target="_blank" href="https://ginkgomic.sharepoint.com/:b:/g/EVTc_LwyaHBLjfSqIQ6qW7sB9TprWA1vq5IIsB4g3ldUgQ?e=dc1yjr"><button className="Button roundedSmall fontSizeXS bold paddingRightL paddingLeftL ctaBackground">阅读更多</button></a>
                                    </p>
                                   </div>
                            </div>
                        </div>
                      </div>
                  </Grid>
              </Grid>

 */}
 {/*              <div className="paddingTopXL">
              <Grid className="darkerBackground roundedBig" justify="center" container direction="row">
                  <Grid item xs>
                      <img className="mainIamgeSection roundedBig" src={special} alt="" />
                  </Grid>
                  <Grid item xs>
                      <div className="paddingLL marginOnMobile">
                          <H2 className="lightText centerText">Special Shareholder Announcement - Revised Dividend Targets</H2>
                          <div className="fullWidth fontSizeS fontWeightNormal lightText paddingTopS paddingRightS">
                          </div>
                          
                          <div className="paddingBottomM paddingRightM paddingTopM fullWidth">
                            <div className="whiteBackground paddingM centerText">
                                   <div className="fontSizeXS fontWeightNormal ">
                                   <p>In response to the recent interest rate hike by the Bank of Canada, we are pleased to announce that the Board of Directors has revised its annual dividend targets.</p>
                                        <p>Effective immediately, the annual dividend rate for Series 1 Preferred Shares will be increased from 8% to 8.25%; and from 7% to 7.75% for Series 2 Preferred Shares. </p>
                                       <p>This increase builds upon the success related to critical planning and purposeful actions taken over the past years. Ginkgo MIC will continue to strategize to provide our valued investors with the confidence of stable returns despite the volatility experienced in the investment market. </p>  
                                        <p>A kind reminder that you may view your portfolio details by signing into the Investor Portal accessible through the top of the Ginkgo MIC website – Investors tab.</p>
                                        <p>If you are interested in adding to your investment, making a referral or have any questions related to your investment, you may contact Yvonne, Investors Relations at yvonne@ginkgomic.com or +1-416-990-5567.</p>    
                                   </div>
                            </div>
                        </div>
                      </div>
                  </Grid>
              </Grid>
 */}
        <div className="paddingTopXL">
              <Grid className="darkerBackground roundedBig" justify="center" container direction="row">
                  <Grid item xs>
                      <img className="mainIamgeSection roundedBig" src={AGM} alt="" />
                  </Grid>
                  <Grid item xs>
                      <div className="paddingLL marginOnMobile">
                          <H2 className="lightText centerText">年度股东大会新闻稿 | GINKGO MIC 在疫情中依然强劲</H2>                          
                          <div className="paddingBottomM paddingRightM paddingTopM fullWidth">
                            <div className="whiteBackground paddingM centerText">
                                   <div className="fontSizeXS fontWeightNormal ">
                                        <p>Ginkgo抵押贷款投资公司（Ginkgo MIC）2021-2022年度股东大会（AGM）于2022年2月4日和5日举行，由首席执行官Henry Tse主持，分为三场会议——英语、普通话和粤语。会议通过虚拟形式进行，以保障所有参与方的健康与安全。在此次年度会议期间，Henry向Ginkgo的投资者详细介绍了公司财务表现、预测和未来战略的相关信息与见解。</p>
                                         <p>
                                            <a target="_blank" href="https://ginkgomic.sharepoint.com/:b:/g/ERTvxbS35YVIiuqN8B_dUTEBEJaODeCAFQuzHX-0lfIipw"><button className="Button roundedSmall fontSizeXS bold paddingRightL paddingLeftL ctaBackground">阅读更多</button></a>
                                        </p>
                                   </div>
                            </div>
                        </div>
                      </div>
                  </Grid>
              </Grid>

     {/*         <Grid className="darkerBackground roundedBig" justify="center" container direction="row">
                  <Grid item xs>
                      <img className="mainIamgeSection roundedBig" src={logo3} alt="" />
                  </Grid>
                  <Grid item xs>
                      <div className="paddingLL marginOnMobile">
                          <H2 className="lightText centerText">Financial Webinar for Working Adults</H2>
                          <div className="fullWidth fontSizeS fontWeightNormal lightText paddingTopS paddingRightS">
                              <p>Hosted by Henry Tse, expert in financial consulting and future planning, this is a unique opportunity for those in the younger working class to gain first-hand insight into the benefits of investing young and how it contributes to building wealth over time.</p>
                          </div>
                          
                          <div className="paddingBottomM paddingRightM paddingTopM fullWidth">
                            <div className="whiteBackground paddingM centerText">
                                <div className="fontSizeM fontWeightBold">LIVE ZOOM WEBINAR</div>
                                <div className="fontSizeXS fontWeightBold">English</div>
                                   <div className="fontSizeXS fontWeightNormal ">
                                        <p>Thursday, June 17th 2021 6PM EST / 3PM PT</p>
                                         <p>
                                            <button className="Button roundedSmall fontSizeXS bold paddingRightL paddingLeftL ctaBackground">REGISTER</button>
                                        </p>
                                   </div>
                            </div>
                        </div>
                      </div>
                  </Grid>
              </Grid>
    */}

         <div className="paddingTopXL">
        
        <Grid className="darkerBackground roundedBig" justify="center" container direction="row">
                <Grid item xs>
                    <img className="mainIamgeSection roundedBig" src={logo2} alt="" />
                </Grid>
                <Grid item xs>
                    <div className="paddingLL marginOnMobile">
                        <H2 className="lightText centerText" >新闻</H2>
                        <div className="fullWidth fontSizeM fontWeightNormal lightText paddingTopM paddingRightXL">
                            <p>阅读Ginkgo最新的新闻动态</p>
                        </div>
                        <div className="paddingBottomML paddingRightM paddingTopL fullWidth">
                            <div className="whiteBackground paddingL centerText">
                                <div className="fontSizeM fontWeightBold">阅读我们的2022年基本面分析报告</div>
                                <div className="fontSizeS fontWeightNormal paddingTopS">
                                    <p>Ginkgo MIC再次获得高投资评级！请阅读下面的2022年报告，了解当前市场预测、预期股息 projections，以及更多关于Ginkgo为何成为今天一个优秀投资选择的重要信息。</p>
                                    <p>
                                        <a target="_blank" href="https://ginkgomic.sharepoint.com/:b:/g/EdM0BQlWBadHhxgsk2TOYFcBaPV4D7PuJZ7Y6YurM-TiyQ"><button className="Button roundedSmall fontSizeXS bold paddingRightL paddingLeftL ctaBackground">阅读更多</button></a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </Grid>
            </Grid>
            
            {/*
            <div className="paddingTopXL">
              <Grid className="darkerBackground roundedBig" justify="center" container direction="row">
                  <Grid item xs>
                      <img className="mainIamgeSection roundedBig" src={henry} alt="" />
                  </Grid>
                  <Grid item xs>
                      <div className="paddingLL marginOnMobile">
                          <H2 className="lightText centerText" >Investment Seminar</H2>
                          <div className="fullWidth fontSizeM fontWeightNormal lightText paddingTopM paddingRightXL">
                              <p>Learn how to invest in mortgages worry-free, and earn a passive income with an annual 7-8% rate of return. Join our free seminar:</p>
                          </div>
                          <div className="paddingBottomML paddingRightM paddingTopL fullWidth">
                            <div className="whiteBackground paddingL centerText">                             
                               <div className="fontSizeM fontWeightBold">2020 Seminar</div>                                
                                    <p>
                                        <a href="https://discover.ginkgomic.com/seminar/"><button className="Button roundedSmall fontSizeXS bold paddingRightL paddingLeftL ctaBackground">FREE SIGN UP</button></a>
                                    </p>
                                </div>
                            </div>
                        </div>
                  </Grid>
              </Grid>
            </div>
            
            <div className="paddingTopXL">
                <Grid className="darkerBackground roundedBig" justify="center" container direction="row">
                    <Grid item xs>
                        <img className="mainIamgeSection roundedBig" src={image2} alt="" />
                    </Grid>
                    <Grid item xs>
                        <div className="paddingLL marginOnMobile">
                            <H2 className="lightText">Seminars</H2>
                            <div className="fontSizeM fontWeightNormal lightText paddingTopM paddingRightXL fullWidth">
                                Don't know where to start investing? Not to worry. We believe that financial freedom is
                                achieved through financial literacy.
                            </div>
                            <div className="paddingBottomML paddingRightXL paddingTopL fullWidth">
                                <div className="whiteBackground paddingL centerText ">
                                    <div className="fontSizeM fontWeightBold">How to get 8% return</div>
                                    <div className="fontSizeS fontWeightNormal paddingTopS">
                                        Learn how you can achieve high returns in real estate without intensive capital.
                                    </div>
                                </div>
                                <div className="paddingTopL fullWidth">
                                    <div className="whiteBackground paddingL centerText ">
                                        <div className="fontSizeM fontWeightBold">Raptors Game 3 Party</div>
                                        <div className="fontSizeS fontWeightNormal paddingTopS">
                                            Learn how you can achieve high returns in real estate without intensive
                                            capital.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </div>
            */}
      </div>
      </div>
      </div>
      </div>
      </div>
      </div>
      </div>
      </div>
      </div>
      </div>
    );
};

export default pure(BodySection);
