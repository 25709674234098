import React from "react";
import { pure } from "recompose";
import { H1 } from "../../../../../../Atoms";
import { OnScrollAnimatior } from "../../../../../../Molecules";
import "./ReportSection.css";
import SingleCard from "./SingleCard/SingleCard";
import Grid from "@material-ui/core/Grid";

import Image1 from "./image1.png";
import Image3 from "./image3.png";
import Image4 from "./image4.png";

const reviews = [
    {
        image: Image1,
        title: <span>优先股</span>,
        text: (
            <span>
                年回报率：9%
                <br /> 适合寻求财富增长的投资者。
            </span>
        ),
        link: "https://ginkgomic.sharepoint.com/:b:/g/EV77zHo4MVZDsMQGnXxKFTEBN_qJd5ieBBuLTdtdDGg6Qg"
    },
/*     {
        image: Image1,
        title: <span>Bonds</span>,
        text: (
            <span>
                Returns of 5% or 6%<br></br> Perfect for investors looking for <br></br>stability such as retirement
            </span>
        ),
        link: "https://bit.ly/2RuFQmH"
    },
 */    {
        image: Image3,
        title: <span>请阅读我们的发行备忘录</span>,
        text: (
            <span>
                明智的投资始于充分的信息了解。所有发行备忘录和豁免分配文件均可在不列颠哥伦比亚省证券委员会网站上查阅。
            </span>
        ),
        link: "https://www.bcsc.bc.ca/search#sort=Relevance&term=ginkgo%20mortgage%20investment%20corporation"
    },
    {
        image: Image4,
        title: <span>绩效投资组合</span>,
        text: <span>了解您的投资如何在我们的日常业务中发挥作用。</span>,
        link: "/investor-ch/our-performance"
    }
];
const ReportSection = props => {
    const isMobile = window.innerWidth < 800;

    const ReviewCards = () =>
        reviews.map(({ image, title, text, link }, i) => (
            <Grid item className="CardGrid paddingRightL">
                <OnScrollAnimatior
                    key={i}
                    animation="fadeInUp"
                    delay={i * 100}
                    preAnimationStyles={{ opacity: 0 }}
                    isMobile={isMobile}
                    onSuccess={scrollAnimatior => console.log("Card Animating", scrollAnimatior)}
                >
                    <SingleCard image={image} title={title} text={text} link={link} />
                </OnScrollAnimatior>
            </Grid>
        ));

    return (
        <div className="pagePadding-Investor paddingTopXL paddingBottomXL GraphSection">
            <Grid className="" justify="center" container direction="row">
                <Grid item xs={5} sm={6}>
                    <div className="GridSection paddingTopS fontWeightNormal fontSizeS lineHeightM fadeInUp">
                    Ginkgo盈信为投资者提供稳健的8%-9%的回报，是分散投资与退休规划的理想选择。不论是加速财富增长还是获取退休收入，我们的按揭资产组合都能让您安心无忧。
                    </div>
                </Grid>
            </Grid>

            <Grid className="paddingTopXXL" container justify="center">
                {ReviewCards()}
            </Grid>
        </div>
    );
};

export default pure(ReportSection);
