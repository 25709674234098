import React from "react";
import { pure } from "recompose";
import { H1 } from "../../../../../Atoms";
import { OnScrollAnimatior } from "../../../../../Molecules";
import "./ProductsSection.css";
import SingleCard from "./ProductCard/ProductCard";

import Grid from "@material-ui/core/Grid";

import howToInvest from "./how-to-invest.png";
import preferredShares from "./preferred-shares.png";
import riskAdverse from "./risk-adverse.png";

import logoTwo from "./logoTwo.png";
import CAASA from "./CAASA.png";
import ONMICA from "./ONMICA.png";
import CCFA from "./CCFA.png";
import logoThree from "./logoThree.png";
const ProductsSection = props => {
    const isMobile = window.innerWidth < 800;

    const productCards = [
        {
            image: howToInvest,
            title: (
                <div className="underline">
                    <span>
                        <a href="/investor/how-to-invest">How to invest</a>
                    </span>
                </div>
            ),
            text: <span>We're different from stocks</span>
        },
        {
            image: preferredShares,
            title: (
                <div className="underline">
                    <span>
                        <a href="/investor/investment-products">Want high yields?</a>
                    </span>
                </div>
            ),
            text: <span>Our Preferred Shares are a great solution.</span>
        },
/*         {
            image: riskAdverse,
            title: (
                <div className="underline">
                    <span>
                        <a href="/investor/investment-products">Want retirement funds?</a>
                    </span>
                </div>
            ),
            text: <span>Our Bonds are a great solution</span>
        }
 */    ];
    const ProductCards = () =>
        productCards.map(({ image, title, text }, i) => (
            <Grid item xs direction="column">
                <OnScrollAnimatior
                    key={i}
                    animation="fadeInUp"
                    delay={i * 100}
                    preAnimationStyles={{ opacity: 0 }}
                    isMobile={isMobile}
                    onSuccess={scrollAnimatior => console.log("Card Animating", scrollAnimatior)}
                >
                    <SingleCard image={image} title={title} text={text} />
                </OnScrollAnimatior>
            </Grid>
        ));
    return (
        <div className="ProductsSection">
            <div className="lighGrayBackground pagePadding-Investor">
                <OnScrollAnimatior animation="fadeInUp" isMobile={isMobile} preAnimationStyles={{ opacity: 0 }}>
                    <div className="paddingTopXL paddingBottomXL">
                        <Grid className="paddingTopL" container direction="row">
                            <Grid item direction="column">
                                <H1 className="fontSizeLL">Products</H1>
                                <div className="slideTwoBody fontSizeS paddingTopM fadeIn paddingBottomM">
                                    If you are looking for high returns of 8-9% in a secured, diversified portfolio,
                                    you're in the right place!
                                </div>
                                <div className="buttonRow"></div>
                            </Grid>
                            {ProductCards()}
                        </Grid>
                    </div>
                </OnScrollAnimatior>
            </div>
            <div className="pagePadding-Investor">
                <OnScrollAnimatior animation="fadeInUp" isMobile={isMobile} preAnimationStyles={{ opacity: 0 }}>
                    <div className="paddingTopL paddingBottomXL">
                        <Grid className="paddingTopL" container direction="row">
                            <Grid item direction="column">
                                <H1 className="fontSizeLL paddingRightM">Associations</H1>
                            </Grid>
                            <Grid item xs={8} direction="column">
                                <div className="slideTwoBodyTwo fontSize26 fontWeight300 paddingTopM fadeIn paddingBottomM">
                                    We are in compliance with the following governing bodies
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                    <div className="line dividerBackground"></div>
                    <div className="paddingTopL paddingBottomL">
                        <Grid className="paddingTopL" container direction="row" justify="center" alignItems="center">
                            <Grid item direction="column">
                                <img className="logoImage" src={logoTwo} alt="" />
                            </Grid>
{/*                             <Grid item direction="column">
                                <img className="logoImage" src={logoThree} alt="" />
                            </Grid>
 */}                        
                            <Grid item direction="column">
                                <img className="logoImageOne" src={CAASA} alt="" />
                            </Grid>
                            <Grid item direction="column">
                                <img className="logoImageTwo" src={ONMICA} alt="" />
                            </Grid>
{/*                             <Grid item direction="column">
                                <img className="logoImageThree" src={CCFA} alt="" />
                            </Grid>
 */}                        </Grid>
                    </div>
                </OnScrollAnimatior>
            </div>
        </div>
    );
};

export default pure(ProductsSection);
