import React, { PureComponent } from 'react'
import { H2 } from '../../../../../Atoms'
import { OnScrollAnimatior } from '../../../../../Molecules'
import { pure } from 'recompose'
import dealsIcon from './deals-icon.svg'
import fundsIcon from './funds-icon.svg'
import investedIcon from './invested-icon.svg'
import loansIcon from './loan-icon.svg'
import axios from 'axios'
import { serURL } from '../../../../../API/url'
import './Facts.css'

class Facts extends PureComponent {  
  
    constructor(props) {  
      super(props)
        this.state = {
        Funded: '1',
        Capital: '82',
        Agent: '730+',
        Loan: '353'
        }
    }

    readSPFacts = async() => {

     const getFacts = await axios.get(serURL+"/investorfacts"
        ).then((response) => response
        )
        .catch((err) => console.log(err));
    this.setState({ TotalInvestor: getFacts.data.Title, TotalDividendPaid: getFacts.data.TotalDividendPaid, ReturnRate: getFacts.data.ReturnRate})
    console.log(getFacts.data)
}

componentDidMount() {
    this.readSPFacts()
}

render() {
//const Facts = props => {
    const isMobile = window.innerWidth < 800
    return (
        <div className='Facts darkBackground lightText'>
            <OnScrollAnimatior animation='fadeIn' isMobile={isMobile} preAnimationStyles={{ opacity: 0 }}>
                <H2>
                財富管理做得好， 理想生活看得到。
            </H2>
                <div className='plainText paddingTopM fontSizeXS'>
                自2011年成立以来，Ginkgo MIC 盈信房贷基金凭借在风险管理、透明度和优质服务上的卓越表现，获得了加拿大领先房贷投资基金（MIC）的声誉，为尊贵的投资者提供稳健的投资回报。
            </div>

            </OnScrollAnimatior>
            <div className='factsRow row paddingTopM'>
{/*                 <div className='singleFactContainer row'>
                    <OnScrollAnimatior animation='pulse'>
                        <img src={dealsIcon} alt='' />
                    </OnScrollAnimatior>
                    <div className='textWrap paddingLeftS'>
                        <OnScrollAnimatior animation='tada'>
                            <b className='fontSizeL '>
                                ${this.state.Funded}
                        </b>
                        </OnScrollAnimatior>
                        <OnScrollAnimatior animation='fadeIn' isMobile={isMobile} preAnimationStyles={{ opacity: 0 }}>
                            <div className='fontSizeXS'>
                                Billion funded
                        </div>
                        </OnScrollAnimatior>
                    </div>
                </div>
 */}                <div className='singleFactContainer row'>
                    <OnScrollAnimatior animation='pulse'>
                        <img src={fundsIcon} alt='' />
                    </OnScrollAnimatior>

                    <div className='textWrap paddingLeftS'>
                        <OnScrollAnimatior animation='tada'>
                            <b className='fontSizeL'>
                                {this.state.TotalDividendPaid}
                        </b>
                        </OnScrollAnimatior>
                        <OnScrollAnimatior animation='fadeIn' isMobile={isMobile} preAnimationStyles={{ opacity: 0 }}>
                            <div className='fontSizeXS'>
                            自成立以来支付的股息总额

                    </div>
                        </OnScrollAnimatior>

                    </div>
                </div>
                <div className='singleFactContainer row'>
                    <OnScrollAnimatior animation='pulse'>
                        <img src={investedIcon} alt='' />
                    </OnScrollAnimatior>

                    <div className='textWrap paddingLeftS'>
                        <OnScrollAnimatior animation='tada'>
                            <b className='fontSizeL'>
                            {this.state.TotalInvestor}
                    </b>
                        </OnScrollAnimatior>
                        <OnScrollAnimatior animation='fadeIn' isMobile={isMobile} preAnimationStyles={{ opacity: 0 }}>
                            <div className='fontSizeXS'>
                            位投资者
                    </div>
                        </OnScrollAnimatior>

                    </div>
                </div>
                <div className='singleFactContainer row'>
                    <OnScrollAnimatior animation='pulse'>
                        <img src={loansIcon} alt='' />
                    </OnScrollAnimatior>

                    <div className='textWrap paddingLeftS'>
                        <OnScrollAnimatior animation='tada'>
                            <b className='fontSizeL'>
                            {this.state.ReturnRate}
                            </b>
                        </OnScrollAnimatior>
                        <OnScrollAnimatior animation='fadeIn' isMobile={isMobile} preAnimationStyles={{ opacity: 0 }}>
                            <div className='fontSizeXS'>
                            回报率
                    </div>
                        </OnScrollAnimatior>

                    </div>
                </div>
            </div>
        </div>
    )
}
}
export default pure(Facts)