import React from "react";
import SingleCard from "./SingleCard/SingleCard";
import { OnScrollAnimatior } from "../../../../../Molecules";
import { H1 } from "../../../../../Atoms";
import { pure } from "recompose";

import cardOne from "./cardOne.png";
import cardTwo from "./cardTwo.png";
import cardThree from "./cardThree.png";

import Grid from "@material-ui/core/Grid";

import "./CardsSection.css";
import HorizontalCard from "./HorizontalCard/HorizontalCard";

const horizontalCards = [
    {
        image: cardOne,
        title: <span> 查看每月最新的投资组合表现</span>,
        text: <span>浏览每月基金投资组合报告。了解所有最新资讯，尽在此处！</span>,
        buttonTitle: "了解更多！",
        link: "/investor-ch/fund-portfolio"
    },
    {
        image: cardTwo,
        title: <span> "非常优秀的回报风险比！"</span>,
        text: (
            <span>Ginkgo 荣获独立研究机构Fundamental Research Corporation的2+评级</span>
        ),
        buttonTitle: "了解更多！",
        link: "/investor-ch/analyst-rating"
    }
];

const verticalCards = [
    {
        image: cardThree,
        title: <span>获取我们的业绩统计数据！</span>,
        text: (
            <span>我们深知，在您投资一家企业之前，需要了解其每一个细节。因此，我们为您完全公开所有信息。</span>
        ),
        buttonTitle: "查看业绩组合",
        link: "/investor-ch/fund-portfolio"
    }
];

const CardsSection = props => {
    const isMobile = window.innerWidth < 1300;
    const HorizontalCards = () =>
        // onSuccess={scrollAnimatior => console.log("Card Animating", scrollAnimatior)}
        // KL: 2025-01-27 - remove console.log from OnScrollAnimator
        horizontalCards.map(({ image, title, text, buttonTitle, link }, i) => (
            <Grid item>
                <OnScrollAnimatior
                    key={i}
                    animation="fadeInUp"
                    delay={i * 100}
                    preAnimationStyles={{ opacity: 0 }}
                    isMobile={isMobile}
                >
                    <div className="paddingRightML">
                        <HorizontalCard image={image} title={title} text={text} buttonTitle={buttonTitle} link={link} />
                    </div>
                </OnScrollAnimatior>
            </Grid>
        ));

    const VerticalCards = () =>
        // onSuccess={scrollAnimatior => console.log("Card Animating", scrollAnimatior)}
        // KL: 2025-01-27 - remove console.log from OnScrollAnimator
        verticalCards.map(({ image, title, text, buttonTitle, link }, i) => (
            <Grid item>
                <OnScrollAnimatior
                    key={i}
                    animation="fadeInUp"
                    delay={i * 100}
                    preAnimationStyles={{ opacity: 0 }}
                    isMobile={isMobile}
                >
                    <SingleCard image={image} title={title} text={text} buttonTitle={buttonTitle} link={link} />
                </OnScrollAnimatior>
            </Grid>
        ));
    const VerticalMobileCards = () =>
        // onSuccess={scrollAnimatior => console.log("Card Animating", scrollAnimatior)}
        // KL: 2025-01-27 - remove console.log from OnScrollAnimator
        horizontalCards.concat(verticalCards).map(({ image, title, text, buttonTitle, link }, i) => (
            <Grid item>
                <OnScrollAnimatior
                    key={i}
                    animation="fadeInUp"
                    delay={i * 100}
                    preAnimationStyles={{ opacity: 0 }}
                    isMobile={isMobile}
                >
                    <SingleCard image={image} title={title} text={text} buttonTitle={buttonTitle} link={link} />
                </OnScrollAnimatior>
            </Grid>
        ));

    return (
        <div className="CardsSection withBackground">
            <H1 className="fadeIn fontSizeLL header">Ginkgo 投资资源</H1>
            {isMobile ? (
                <div className="paddingL">
                    <Grid container direction="row" justify="enter">
                        {VerticalMobileCards()}
                    </Grid>
                </div>
            ) : (
                <Grid container direction="row" justify="enter">
                    <Grid item xs="7" direction="column">
                        <Grid container direction="row" justify="flex-end">
                            {HorizontalCards()}
                        </Grid>
                    </Grid>
                    <Grid item xs="5" direction="column">
                        {VerticalCards()}
                    </Grid>
                </Grid>
            )}
        </div>
    );
};

export default pure(CardsSection);
